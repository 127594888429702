import React from 'react';
import { Button } from '@web-passion/uikit';

import { GreenColour, OrangeColour } from '../../utils/variables';

export default function SubmitButton({
  children,
  primary,
  secondary,
  green,
  orange,
  style,
  className,
  label,
  processing,
  disabled,
  ...other
}) {
  const customColor = () => {
    if (green) {
      return GreenColour;
    }
    if (orange) {
      return OrangeColour;
    }
    return undefined;
  };

  const textColor = () => {
    if (green || orange) {
      return '#fff';
    }
    return undefined;
  };

  const buttonProps = {
    label,
    primary,
    secondary,
    color: customColor(),
    textColor: textColor(),
    style,
    className,
    disabled,
    loading: processing,
    ...other,
  };
  return <Button {...buttonProps}>{children}</Button>;
}
